import { createContext, useContext } from "react";
import logovw from "../assets/logovw.svg";

/* Models */
import polotrack from "../assets/molelos/polo-track.webp";
import virtus from "../assets/molelos/virtus.webp";
import tcross from "../assets/molelos/tcross.webp";
import amarok from "../assets/molelos/amarok.webp";
import taos from "../assets/molelos/taos.webp";
import saveiro from "../assets/molelos/saveiro.webp";
import nivus from "../assets/molelos/nivus.webp";

export const ConsContext = createContext();

export const useCons = () => {
  const context = useContext(ConsContext);
  if (!context) throw new Error("useCons must be used within a ConsContext");
  return context;
};

export const ConsContextProvider = ({ children }) => {
  const prices = {
    polo: {
      cuota: "205.000",
      valorunidad: "26.219.775"
    },
    virtus: {
      cuota: "300.000",
      valorunidad: "24.513.150"
    }, 
    tcross: {
      cuota: "380.000",
      valorunidad: "31.793.517"
    },
    amarok: {
      cuota: "380.000",
      valorunidad: "38.807.430"
    },
    taos: {
      cuota: "350.000",
      valorunidad: "40.464.156"
    },
    saveiro: {
      cuota: "285.800",
      valorunidad: "24.775.500"
    },
    nivus: {
      cuota: "360.000",
      valorunidad: "30.472.358"
    }
  };

  const modelos = [
    {
      id: 1,
      nombre: "Polo Track",
      modelo: "Volkswagen Polo Track",
      cuota: prices.polo.cuota,
      plan: `Podés financiar hasta el 70%`,
      text1: "Retirás con el 30% del valor o tu usado",
      text2: "Adjudicación pactada en cuota 3",
      text3: `Valor de la unidad $${prices.polo.valorunidad}`,
      img: polotrack,
      dataButton: "Plan Polo Track"
    },
    /* {
      id: 2,
      nombre: "Virtus",
      modelo: "Volkswagen Virtus",
      cuota: prices.virtus.cuota,
      plan: `Financiá hasta el 100%`,
      text1: "Tomamos tu usado como anticipo",
      text2: "Opcional: Adjudicado",
      text3: `Valor de la unidad $${prices.virtus.valorunidad}`,
      img: virtus,
      dataButton: "Plan Virtus"
    }, */
    {
      id: 3,
      nombre: "T-Cross",
      modelo: "Volkswagen T-Cross",
      cuota: prices.tcross.cuota,
      plan: `Financiá hasta el 70%`,
      text1: "Tomamos tu usado como anticipo",
      text2: "Opcional: Adjudicado",
      text3: `Valor de la unidad $${prices.tcross.valorunidad}`,
      img: tcross,
      dataButton: "Plan T-Cross"
    },
    {
      id: 4,
      nombre: "Amarok",
      modelo: "Volkswagen Amarok",
      cuota: prices.amarok.cuota,
      plan: `Financiá hasta el 60%`,
      text1: "Tomamos tu usado como anticipo",
      text2: "Opcional: Adjudicado",
      text3: `Valor de la unidad $${prices.amarok.valorunidad}`,
      img: amarok,
      dataButton: "Plan Amarok"
    },
    {
      id: 5,
      nombre: "Taos",
      modelo: "Volkswagen Taos",
      cuota: prices.taos.cuota,
      plan: `Financiá hasta el 60%`,
      text1: "Tomamos tu usado como anticipo",
      text2: "Opcional: Adjudicado",
      text3: `Valor de la unidad $${prices.taos.valorunidad}`,
      img: taos,
      dataButton: "Plan Taos"
    },
    /* {
      id: 6,
      nombre: "Saveiro",
      modelo: "Volkswagen Saveiro",
      cuota: prices.saveiro.cuota,
      plan: `Financiá hasta el 90%`,
      text1: "Tomamos tu usado como anticipo",
      text2: "Opcional: Adjudicado",
      text3: `Valor de la unidad $${prices.saveiro.valorunidad}`,
      img: saveiro,
      dataButton: "Plan Saveiro"
    }, */
    {
      id: 7,
      nombre: "Nivus",
      modelo: "Volkswagen Nivus",
      cuota: prices.nivus.cuota,
      plan: `Financiá hasta el 70%`,
      text1: "Tomamos tu usado como anticipo",
      text2: "Opcional: Adjudicado",
      text3: `Valor de la unidad $${prices.nivus.valorunidad}`,
      img: nivus,
      dataButton: "Plan Nivus"
    }
  ];

  const themeOptions = {
    acentcolor: "#001e50",
    dealer: "",
    carBrand: "Volkswagen",
    brand: logovw,
    logo: "",
    urlHook: "https://hook.us1.make.com/8m67hw85tue0ouf6ec28g7oh6c0b2n7r"
  };

  const modelsOptions = modelos.map((model) => {
    return {
      value: model.dataButton,
      text: `${model.dataButton} (Cuota $${model.cuota})`,
      disabled: model.disabled
    };
  });

  console.log(modelsOptions);

  return (
    <ConsContext.Provider
      value={{
        prices,
        modelos,
        modelsOptions,
        themeOptions
      }}
    >
      {children}
    </ConsContext.Provider>
  );
};
